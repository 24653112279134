<template>
  <tbody v-if="$wait.is('response')">
    <tr>
      <td 
        v-for="(column, index) in columns" 
        :key="index"
      >
        <content-placeholder />
      </td>
    </tr>
  </tbody>
  <tbody 
    v-else 
    data-test-id="history-table-autocheck"
  >
    <GridTooltip
      id="grid-tooltip"
      style="display: none"
      v-tippy-html
      v-bind="{ grid }"
    />
    <tr 
      v-for="(item, index) in rows"
      :key="index"
    >
      <td>
        <router-link
          v-if="item.response"
          :to="getLink(item)"
          class="button is-size-7-mobile button-extras"
          @click.native="track('vrm')"
        >
          {{ item.vrm }}
        </router-link>
        <span
          v-else-if="item.vrm"
          @click="track('disabled_vrm')"
          disabled
          class="button is-size-7-mobile is-dark is-outlined"
        >
          {{ item.vrm }}
        </span>
        <span
          v-else
          @click="track('N/A_vrm')"
          disabled
          class="button is-size-7-mobile is-dark is-outlined"
        >
          N/A
        </span>
      </td>
      <td class="is-hidden-touch">
        <div>
          <span 
            v-if="item.response" 
            class="is-size-6 has-text-dark"
          >
            <router-link
              class="is-valigned is-size-6 has-text-dark"
              :to="getLink(item)"
            >
              {{ truncate(buildDescription(item.response.response)) }}
            </router-link>
          </span>
          <span
            v-else 
            class="is-size-6 has-text-grey-light is-valigned"
          >
            <span>N/A</span>
            <span
              v-tippy="{ placement: 'right' }"
              title="Failed to perform a check"
              class="icon has-text-danger"
            >
              <i class="fal fa-exclamation-circle" />
            </span>
          </span>
          &nbsp;
          <router-link
            class="tag is-dark has-text-weight-bold"
            :to="{
              name: 'autocheck-summary',
              params: { uuid: item.batch.uuid }
            }"
            v-if="item.batch && item.batch.uuid"
          >
            Batch
          </router-link>
        </div>
      </td>
      <td>{{ formatDate(item.createdAt) }}</td>
      <td
        v-if="depth !== 'user' && item.user" 
        class="is-hidden-touch"
      >
        {{ item.user.name }}
      </td>
      <td 
        v-if="depth === 'org' && item.branch"
        class="is-hidden-touch"
      >
        {{ item.branch.name }}
      </td>
      <td>
        <a
          v-if="item.response"
          @mouseover="updateTooltip(item.response)"
          v-tippy="{ html: '#grid-tooltip' }"
        >
          <span
            class="status-tag tag is-medium is-size-7-mobile has-text-weight-bold"
            :class="`is-${checkStatus(item.response.response).class}`"
          >{{ checkStatus(item.response.response).text }}</span>
        </a>
        <span
          v-else
          class="tag has-border is-light is-medium is-size-7-mobile has-text-grey-light has-text-weight-semibold"
        >
          N/A
        </span>
      </td>
      <td>
        <div class="field is-grouped">
          <p class="control mr-3">
            <button
              v-tippy
              :class="{ 'is-loading': $wait.is(`loading-${index}`) }"
              :disabled="$wait.is(`loading-${index}`)"
              title="Recheck vehicle"
              v-if="hasAutoCheck"
              class="button is-size-7-mobile is-soft is-outlined"
              @click="buyCheck(item, index)"
              data-test-id="autocheck-table-recheck"
            >
              <span class="icon">
                <i class="fal fa-sync-alt" />
              </span>
            </button>
          </p>
          <p class="control">
            <button
              v-tippy
              :class="{ 'is-loading': $wait.is(`loading-${index}`) }"
              :disabled="$wait.is(`loading-${index}`)"
              class="button is-size-7-mobile is-soft is-outlined"
              title="Calculate value"
              @click="value(item, index)"
              data-test-id="autocheck-table-companion"
            >
              <span class="icon">
                <i class="fal fa-pound-sign" />
              </span>
            </button>
          </p>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import * as autocheck from 'modules/autocheck/services'
import * as companion from 'modules/companion/services'
import truncate from 'truncate'
import { formatDate } from '@/utils/date.utils.ts'
import { formatMoney, formatNumber } from 'accounting'
import { mapGetters } from 'vuex'
import GridTooltip from 'modules/autocheck/components/AutoCheckGridTooltip'
export default {
  name: 'AccountHistoryCheck',
  components: {
    contentPlaceholder: () => import('core/components/ContentPlaceholder'),
    GridTooltip
  },
  data: () => ({ tooltipData: null }),
  props: {
    depth: {
      type: String,
      required: false,
      default: () => 'user'
    },
    preview: {
      type: Boolean,
      default: () => false
    },
    rows: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('auth', ['isCompanionUser', 'hasAutoCheck']),
    grid() {
      return autocheck.getGrid(this.tooltipData)
    }
  },
  methods: {
    formatDate,
    track(source) {
      this.$mxp.track(`${this.depth}_history_autocheck_${source}_view_check`)
    },
    checkStatus(item) {
      return autocheck.getStatus(item)
    },
    buildDescription(response) {
      return autocheck.buildDescription(response)
    },
    getLink(item) {
      return { name: 'autocheck', params: { uuid: item.response.uuid } }
    },
    truncate(value) {
      return truncate(value, 45)
    },
    async buyCheck(item, index) {
      this.$mxp.track(`${this.depth}_history_autocheck_recheck_search`)
      this.$wait.start(`loading-${index}`)
      try {
        const data = await autocheck.precheck({
          value: item.vrm,
          type: 'vrm'
        })

        this.$modal.open('autocheck/AutoCheckOptions', {
          vrm: data.vrm,
          vin: data.vin,
          description: autocheck.buildPreDescription(data),
          mileage: data.mileage
        })
      } catch (err) {
        const msg = err?.response?.data?.error?.message
        this.$notify(msg || 'Currently unable to perform a check')
      } finally {
        this.$wait.end(`loading-${index}`)
      }
    },
    async value({ vrm }, index) {
      if (!this.isCompanionUser) {
        this.$mxp.track(`${this.depth}_history_autocheck_companion_upsell`)
        this.toggleUpsell()
      } else {
        this.$mxp.track(`${this.depth}_history_autocheck_companion_request`)
        this.$wait.start(`loading-${index}`)
        await companion.value({ vrm })
        this.$wait.end(`loading-${index}`)
      }
    },
    toggleUpsell() {
      this.$modal.open('companion/UpsellCompanion')
    },
    pounds(value) {
      return formatMoney(value, '£', 0)
    },
    percentage(value) {
      return `${formatNumber(value, 2)}%`
    },
    number(value) {
      return `${formatNumber(value)}`
    },
    updateTooltip({ response }) {
      this.tooltipData = response
    }
  }
}
</script>

<style lang="sass" scoped>
.status-tag
  min-width: 6em
</style>
